<template>
    <DateRanges @filterByDate="$emit('filterByDate', $event)" :date="date"/>
    <table id="datatable" class="table table-striped my-2 dataTable w-100">
        <thead>
            <tr>
                <th class="no-sort"></th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-clock me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.evtDate') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-circle-nodes me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.networkLabel') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.chargedAmount') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-user-tag me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.riderType') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-route me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.line') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-location-dot me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.stopName') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-bus me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.tapType') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-chart-simple me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.validationTapStatus') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-route me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.journeyNumber') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-route me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.rideNumber') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-road me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.transportMode') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-wallet me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.formFactor') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-coins me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.amount') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.transactionId') }}
                </th>
                <th class="text-center text-nowrap" role="button">
                    <i class="fa-solid fa-book-atlas me-2"></i>
                    {{ $t('DataTable.Travels.Journeys.labels.validationTapId') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="tr in tbody" :key="tr.id">
                <td class="dtr-control text-center align-middle">
                    <i class="fa-solid fa-circle-plus"></i>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.evtDate }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.networkLabel }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.chargedAmount }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Journeys.riderType.${tr.riderType}`) }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        <!-- <strong>
                            {{ tr.lineNumber }}
                        </strong>
                        <br> -->
                        {{ tr.lineName }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.stopName }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Journeys.tapType.${tr.tapType}`) }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Journeys.validationTapStatus.${tr.validationTapStatus}`) }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace" v-if="tr.journeyNumber != '-1'">
                        {{ tr.journeyNumber }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace" v-if="tr.rideNumber != '-1'">
                        {{ tr.rideNumber }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Journeys.transportMode.${tr.transportMode}`) }}
                    </span>
                </td>
                <td class="text-center text-nowrap align-middle">
                    <span class="font-monospace">
                        {{ this.$t(`DataTable.Travels.Journeys.formFactor.${tr.formFactor}`) }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.amount }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.transactionId }}
                    </span>
                </td>
                <td class="text-center text-wrap align-middle">
                    <span class="font-monospace">
                        {{ tr.validationTapId }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import $ from 'jquery'
import DateRanges from '@/components/DateRanges.vue'
export default {
    name: 'JourneysDataTable',
    props: {
        date: String,
        tbody: Array
    },
    emits: ['filterByDate'],
    components: { 
        DateRanges 
    },
    created() {
        $(document).ready(() => {
            var JourneysDataTable = $('#datatable').DataTable({
                dom: `<'row g-2 justify-content-center justify-content-lg-between align-items-baseline'
						<'col-sm-12 col-lg-4'l>
						<'col-sm-12 col-lg-4'<"dater">>
						<'col-sm-12 col-lg-4'f>>` +
                    "<'row pt-2'<'col-sm-12'tr>>" +
                    "<'row g-2 align-items-center'<'col-sm-12 col-md-5 text-center text-md-start'i><'col-sm-12 col-md-7'p>>",
                language: {
                    lengthMenu: `${this.$t('DataTable.lengthMenu')} ${this.$t(`DataTable.Travels.${this.$route.name}.RouteName`)}`,
                    search: '<i class="fa-solid fa-magnifying-glass"></i>',
                    searchPlaceholder: `${this.$t('DataTable.search')} ${this.$t(`DataTable.Travels.${this.$route.name}.RouteName`)}`,
                    info: `${this.$t('DataTable.info')} ${this.$t(`DataTable.Travels.${this.$route.name}.elements`)}`,
                    paginate: {
                        previous: '<i class="fa-solid fa-circle-chevron-left"></i>',
                        next: '<i class="fa-solid fa-circle-chevron-right"></i>'
                    },
                    zeroRecords: this.$t('DataTable.zeroRecords'),
                    infoFiltered: this.$t('DataTable.infoFiltered'),
                    infoEmpty: `${this.$t('DataTable.infoEmpty')} ${this.$t(`DataTable.Travels.${this.$route.name}.elements`)}`,
                },
                responsive: {
                    details: {
                        type: 'column',
                        renderer: (api, rowIdx, columns) => {
                            var data = $.map(columns, (col) => {
                                if (col.hidden === false) {
                                    return ''
                                }
                                var row = $('<tr>', {
                                    'data-dt-row': col.rowIndex,
                                    'data-dt-column': col.columnIndex
                                })
                                    .append(
                                        $('<td>', {
                                            class: "py-2"
                                        })
                                            .html(col.title)
                                            .css('font-size', '14px'))
                                    .append(
                                        $('<td>', {
                                            class: col.className
                                        }).html(col.data))

                                return row.get(0).outerHTML
                            }).join('')
                            return data ? $('<table class="w-100 table-sm">').append(data) : false
                        }
                    }
                },
                columnDefs: [{
                    className: 'dtr-control',
                    orderable: false,
                    targets: 0
                }],
                order: [
                   [1, 'ASC'] 
                ]
            })
            $('.dater').append($(".dataTables_date"))
            $('input').removeClass('form-control form-control-sm')
            $('select').removeClass('form-select form-select-sm')

            JourneysDataTable.on('responsive-resize', (e, datatable) => {
                datatable.responsive.rebuild().columns.adjust().responsive.recalc()
            })

            JourneysDataTable.on('responsive-display', (e, api, row, showHide) => {
                var rowOpened = $(api.row(row.index()).node())
                if (showHide) {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-circle-minus"></i>`)
                } else {
                    rowOpened.find('td.dtr-control').empty().append(`<i class="fa-solid fa-circle-plus"></i>`)
                }
            })
        })
    }
}
</script>

<style></style>