<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 mb-5">
            <h1 class="text-second text-center">
                <i class="fa-solid fa-route me-2"></i>
                {{ $t(`Travels.${this.$route.name}.RouteName`) }}
            </h1>
        </div>
        <div class="col-12 bg-second shadow-first rounded-5 p-4" v-if="!loader && journeys">
            <DataTable :date="selectedDate" :tbody="journeys" @filterByDate="filterByDate"/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import cc from 'currency-codes'
import DataTable from "../../components/DataTables/JourneysDataTable.vue"
export default {
    name: 'JourneysView',
    components: {
        DataTable
    },
    computed: {
        ...mapState(['loader', 'credentials']),
        ...mapGetters(['ApiPath'])
    },
    data() {
        return {
            selectedDate: null,
            journeys: null
        }
    },
    methods: {
        ...mapMutations(['setLoader']),
        async getJourneys(startDate, endDate) {
            this.setLoader(true)
            if (!startDate) {
                startDate = moment().subtract(29, 'days').set({hour:0,minute:0,second:0}).format()
            }
            if (!endDate) {
                endDate = moment().set({hour:23,minute:59,second:59}).format()
            }

            const journeys = []

            for (var i = 0; i < this.credentials.cardAccounts.length; i++) {
                var transactions = null
                try {
                    transactions = await this.$http.get(`${this.ApiPath}/transactions`, {
                        params: {
                            aggregationId: this.credentials.cardAccounts[i].aggregationId,
                            cardSequence: this.credentials.cardAccounts[i].cardSequence,
                            startDate,
                            endDate
                        }
                   })
                } catch (err) {
                    // console.log(err)
                    transactions = {
                        data: {
                            transactions: []
                        }
                    }
                }
                for (var x = 0; x < transactions.data.transactions.length; x++) {
                    var transaction = transactions.data.transactions[x]
                    var currency = cc.number(transaction.currency)
                    var journeyDetails = null
                    try {
                        journeyDetails = await this.$http.get(`${this.ApiPath}/journeyDetails`, {
                            params: {
                                aggregationId: this.credentials.cardAccounts[i].aggregationId,
                                cardSequence: this.credentials.cardAccounts[i].cardSequence,
                                accountingPeriodId: transaction.accountingPeriodId
                            }
                        })
                    } catch (err) {
                        // console.log(err)
                        journeyDetails = {
                            data: {
                                taps: []
                            }
                        }
                    }
                    for (var y = 0; y < journeyDetails.data.taps.length; y++) {
                        var journey = journeyDetails.data.taps[y] 
                        journey['transactionId'] = journeyDetails.data.transactionId
                        journey['formFactor'] = this.credentials.cardAccounts[i].formFactor
                        journey.evtDate = this.$formatDate(journey.evtDate, localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                        journey.amount = this.$formatAmount(localStorage.getItem('session_language'), journey.amount, currency.code)
                        journey.chargedAmount = this.$formatAmount(localStorage.getItem('session_language'), journey.chargedAmount, currency.code)
                        journeys.push(journey)
                    }
                }
            }
            this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
            this.setLoader(false)
            return journeys
        },
        async filterByDate(when) {
            this.journeys = null
            this.journeys = await this.getJourneys(when.startDate, when.endDate)
        }
    },
    async created() {
        this.journeys = await this.getJourneys()
    }
}
</script>