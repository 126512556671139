<template>
    <nav class="d-flex justify-content-between align-items-center flex-wrap items-get-in m-0 py-1 px-2">
        <div class="col-12 col-md-4 offset-md-4 mb-2 mb-md-0 bg-first section-nav rounded-5">
            <router-link to="/journeys" class="navbar-link p-2">
                <i class="fa-solid fa-route fs-2 me-2"></i>
                <h4>
                    {{ $t('Travels.Navbar.Journeys') }}
                </h4>
            </router-link>
            <router-link to="/payments" class="navbar-link p-2">
                <i class="fa-solid fa-credit-card fs-2 me-2"></i>
                <h4>
                    {{ $t('Travels.Navbar.Payments') }}
                </h4>
            </router-link>
        </div>
        <div class="col-12 col-md-4 text-center text-md-end">
            <router-link to="/" class="btn btn-auth btn-nav rounded-5" @click="this.$store.dispatch('logout')">
                <i class="fa-solid fa-right-from-bracket fs-2 me-2"></i>
                <span class="fs-4">
                    {{ $t('Travels.Navbar.Logout') }}
                </span>
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComp'
}
</script>

<style lang="scss" scoped>
</style>