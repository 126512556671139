<template>
    <header class="d-flex flex-wrap bg-first">
        <div class="col-12 d-flex justify-content-center flex-wrap py-1" v-if="!credentials">
            <LanguagesSelect class="select-auth fs-4"/>
        </div>
        <div class="col-12" v-if="credentials">
            <Navbar/>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LanguagesSelect from '@/components/LanguagesSelect.vue'
import Navbar from './Navbar.vue'
export default {
    name: 'HeaderComp',
    components: {
        LanguagesSelect,
        Navbar
    },
    computed: {
        ...mapState(['credentials']),
        ...mapGetters(['ApiKey'])
    }
}
</script>

<style lang="scss" scoped>
</style>